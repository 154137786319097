<script lang="ts" setup>
import { ParagraphCtaSidebar } from '~/api/graphql/generated'
import { ButtonProps } from '../components/CtaSidebar.vue'

const props = defineProps<{
  entity: ParagraphCtaSidebar
}>()

const buttonProps: Ref<ButtonProps> = ref({
  to: props.entity.fieldLink?.url?.path ?? '',
  title: props.entity.fieldLink?.title ?? '',
  target: props.entity.fieldLink?.attribute ?? '_self',
})
</script>

<template>
  <CtaSidebar v-bind="buttonProps" />
</template>
